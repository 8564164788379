import request from '@http';

// 2022/05/24 王江毅 库房列表
export function getStockList(params) {
    return request({
        method: 'GET',
        url: '/api/assets/stock/list',
        params
    })
}

// 2022/05/24 王江毅 添加库房
export function stockPublish(data) {
    return request({
        method: 'put',
        url: '/api/assets/stock/publish',
        data
    })
}

// 2022/05/24 王江毅 库房所有列表
export function getStockListAll() {
    return request({
        method: 'GET',
        url: '/api/assets/stock/listall'
    })
}

// 2022/05/24 王江毅 根据库房uuid查询入库人
export function getStockerListBystocksUuid(uuid) {
    return request({
        method: 'GET',
        url: `/api/assets/stock/stocker/${uuid}`,
    })
}

// 2022/05/27 王江毅 添加入库人
export function stockerPublish(data) {
    return request({
        method: 'put',
        url: '/api/assets/stock/stocker/publish',
        data
    })
}